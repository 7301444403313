import { Component, ElementRef, ViewChild } from '@angular/core';
import { RandomwordgenService } from '../randomwordgen.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-wordinput',
  templateUrl: './wordinput.component.html',
  styleUrls: ['./wordinput.component.css']
})
export class WordinputComponent {
  @ViewChild('wordInput', {static: false}) wordInput: ElementRef;
  public word = '';
  public class = 'default';

  constructor(
    private service: RandomwordgenService,
    private http: HttpClient
  ) {
  }

  /**
   * Static method that returns whether a word complies to the "two words nine letters" standard.
   * @param word string to be checked.
   * @return whether the word is valid.
   */
  static isValid(word: string): boolean {
    // debugger;
    word = word.trim();
    if (word.length > 22) { return false; }
    if (word.match(/\s/g) === null || word.match(/\s/g).length !== 1) { return false; }
    if (word.match(/\w/g) === null || word.match(/\w/g).length !== 9) { return false; }
    let spaces = 0;
    for (let i = 0; i < word.length; i++) {
      if (word.charAt(i) === ' ') { spaces++; }
    }
    return spaces === 1;
  }

  /**
   * Hides the modal that is used to add a word to the list of candidates.
   */
  hideModal() {
    this.class = 'default';
    this.word = '';
  }

  /**
   * Suggests a word by getting the word from the input field of the modal and sending it to the {@link newCandidate} method. Also checks
   * whether a word is valid and whether it already exists.
   */
  suggest() {
    if (WordinputComponent.isValid(this.word)) {
      this.newCandidate(this.word);
    }
    this.hideModal();
  }

  /**
   * Adds the {@param newWord} to the candidates list on the server using {@link service}.
   * @param newWord the word to be added.
   */
  newCandidate(newWord: string) {
    if (WordinputComponent.isValid(newWord)) {
      this.http.post(window.location.origin + '/addCand.php', newWord).subscribe();
    }
  }

  /**
   * Updates the class of the suggestion input field.
   */
  updateClass(): void {
    if (WordinputComponent.isValid(this.word)) {
      this.class = 'valid';
    } else {
      this.class = 'not-valid';
    }
  }

  focusOnInput() {
    setTimeout(() => { this.wordInput.nativeElement.focus(); }, 490);
  }
}
