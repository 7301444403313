import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Word } from '../word';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(
    private service: AdminService
  ) {
    this.quality = 1;
  }
  candidates: string[] = [];
  selectedCat: string;
  quality: number;

  protected readonly faTimes = faTimes;
  protected readonly faCheck = faCheck;

  ngOnInit() {
    this.getCandidates();
  }

  /**
   * Gets the candidates from the server using {@link service} and puts them in the {@link candidates} array.
   */
  getCandidates() {
    this.service.getCandidates().subscribe(res => {
      let resString = res.toString();
      while (resString.length > 0) {
        const word = resString.substring(0, resString.search(/;/));
        resString = resString.replace(word + ';', '');
        this.candidates.push(word);
      }
    });
  }

  /**
   * Adds the word that is now being displayed by first turning it into a {@link Word} object using the parameters {@link selectedCat} and
   * {@link quality} that are selected, then sending that to the {@link service} and calling {@link removeWord}.
   */
  addWord() {
    const word: Word = new Word();
    word.content = this.candidates[0];
    word.category = this.selectedCat;
    word.quality = this.quality;
    this.service.addWord(word);
    this.removeWord();
  }

  /**
   * Removes the word that is now being displayed by first removing it from the server and then from the {@link candidates} array.
   */
  removeWord() {
    this.service.removeCandidate(this.candidates[0]);
    this.candidates.splice(0, 1);
  }

  /**
   * Bans a word from ever being suggested again.
   */
  banWord() {
    this.service.banWord(this.candidates[0]);
    this.removeWord();
  }
}
