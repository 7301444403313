import { Component, OnInit } from '@angular/core';
import { RandomwordgenService } from '../randomwordgen.service';
import { CookieService } from 'ngx-cookie-service';
import {faPause, faStepForward} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-random-word',
  templateUrl: './random-word.component.html',
  styleUrls: ['./random-word.component.css']
})
/**
 * Component used to present a word to the user depending on their input.
 * @author Luc Timmerman
 */
export class RandomWordComponent implements OnInit {
  CATEGORIES = ['klassiek', 'politiek', 'studentikoos', 'vulgair', 'informatica', 'heemgaard', 'overig'];

  constructor(
    private service: RandomwordgenService,
    private cookies: CookieService
  ) {
    this.quality = 1;
    this.theList = [];
    this.theNumber = this.theList.length;
    this.cats = [true, true, true, true, true, true, true];
  }
  /**
   * The word to be presented.
   */
  theWord: String;
  /**
   * The array of words that comply to the users input.
   */
  theList: String[];
  /**
   * The number of words that comply to the users input, i.e. the length of {@link theList}.
   */
  theNumber: number;
  /**
   * The categories in array form. They're in this order:
   * Klassiek - Politiek - Vulgair - Studentikoos - Informatica - Heemgaard - Overig
   */
  cats: boolean[];
  /**
   * The quality the user has set.
   */
  quality: number;
  /**
   * The class that the pause button has.
   */
  pauseclass = '';
  private timeout: number;

  /**
   * Checks whether a certain cookie value is a valid settings string.
   * @param value the value of the cookie
   */
  static isValidCookie(value: string): boolean {
    if (value.length !== 8) {
      return false;
    }
    if (+value.charAt(7) < 1 || +value.charAt(7) > 5) {
      return false;
    }
    for (let i = 0; i < value.length - 1; i++) {
      if (+value.charAt(i) < 0 || +value.charAt(i) > 1) {
        return false;
      }
    }
    return true;
  }

  ngOnInit(): void {
    this.getSettingsFromCookie();
    this.firstWord();
  }

  /**
   * Starts a new timer.
   */
  newWordTimer(): void {
    this.getRandomWord();
    const me: RandomWordComponent = this;
    this.timeout = setTimeout(() => me.newWordTimer(), 3000);
  }

  /**
   * Called when the first word is to be displayed for speed.
   */
  firstWord(): void {
    this.service.getList().subscribe(words => {
      words.forEach(word => {
        if (this.cats[this.CATEGORIES.findIndex(cat => cat === word.category)] === true && word.quality >= this.quality) {
          this.theList.push(word.content);
        }
      });
      this.theNumber = this.theList.length;
      this.newWordTimer();
    });
  }

  /**
   * Gets a random word from {@link theList} and, if {@link theList} is empty, asks for a new list.
   */
  getRandomWord(): void {
    const index: number = Math.floor(Math.random() * this.theList.length);
    this.theWord = this.theList.splice(index, 1)[0];
    if (this.theList.length < 2) {
      this.getList();
    }
  }

  /**
   * Refreshes {@link theList} and gets a new {@link theWord} from it.
   */
  refreshList(): void {
    this.getList();
    this.getRandomWord();
    this.storeSettingsInCookie();
  }

  /**
   * Gets a new list depending on the parameters using the {@link service}. It filters the reaction of the {@link service} using the
   * parameters.
   */
  public getList(): void {
    this.service.getList().subscribe(words => {
      const res: string[] = [];
      for (const word of words) {
        if (this.cats[this.CATEGORIES.findIndex(cat => cat === word.category)] === true && word.quality >= this.quality) {
          res.push(word.content);
        }
      }
      this.theNumber = res.length;
      this.theList = res;
    });
  }

  /**
   * Checks whether {@link theList} is empty.
   */
  listIsEmpty(): boolean {
    return this.theList === null || this.theList.length === 0;
  }

  /**
   * Gets the preferences of the users stored in cookies.
   */
  getSettingsFromCookie(): void {
    if (this.cookies.check('prefs') && RandomWordComponent.isValidCookie(this.cookies.get('prefs'))) {
      for (let i = 0; i < this.cookies.get('prefs').length - 1; i++) {
        this.cats[i] = (this.cookies.get('prefs').charAt(i) === '1');
      }
      this.quality = +this.cookies.get('prefs').charAt(this.cookies.get('prefs').length - 1);
    }
  }

  /**
   * Stores the settings in cookies.
   */
  storeSettingsInCookie(): void {
    let sets = '';
    this.cats.forEach(cat => sets += cat ? '1' : '0');
    sets += this.quality.toString();
    this.cookies.set('prefs', sets);
  }

  /**
   * Function that pauses the renewal of random words.
   */
  pause() {
    if (this.pauseclass !== '') {
      this.pauseclass = '';
      this.newWordTimer();
    } else {
      this.pauseclass = 'selected';
      clearTimeout(this.timeout);
    }
  }

  protected readonly faStepForward = faStepForward;
  protected readonly faPause = faPause;
}
