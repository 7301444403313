import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Word } from './word';

@Injectable({
  providedIn: 'root'
})
/**
 * Service to be used for all admin operations, i.e. adding or removing words or candidates.
 */
export class AdminService {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Gets the candidates as a string. The candidates are separated by a ; character.
   */
  getCandidates(): Observable<string> {
    return this.http.get(window.location.origin + '/getCandidates.php', {responseType: 'text'});
  }

  /**
   * Adds a word object to the JSON file on the server so that it can be used by the application. Also removes the candidate from the
   * candidates lsit.
   * @param newWord the {@link Word} to be added
   */
  addWord(newWord: Word): void {
    this.removeCandidate(newWord.content);
    this.http.post(window.location.origin + '/f9Q9o9iNgI.php', newWord.toJSON()).subscribe();
  }

  /**
   * Removes a candidate from the candidates list of the server.
   * @param cand the candidate to be removed
   */
  removeCandidate(cand: string) {
    this.http.get(window.location.origin + '/Q2cwt6joUA.php?w=' + cand).subscribe();
  }

  /**
   * Checks whether {@param pass} is the correct password.
   * @param pass the password to check.
   * @return whether the password is correct.
   */
  checkPassword(pass: string): Observable<boolean> {
    return this.http.get<boolean>(window.location.origin + '/pass.php?p=' + pass);
  }

  banWord(word: string) {
    this.http.post(window.location.origin + '/eSoYrH994A.php', word).subscribe();
  }
}
