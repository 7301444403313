/**
 * Class used to bundle all aspects of a word.
 */
export class Word {
  /**
   * The quality of the word.
   */
  quality: number;
  /**
   * The category of the word.
   */
  category: string;
  /**
   * The actual word.
   */
  content: string;

  /**
   * Method to turn a Word object into a string that can be posted to the server.
   */
  toJSON(): string {
    return '{' +
      '\"quality\": ' + this.quality +
      ', \"category\": \"' + this.category +
      '\", \"content\": \"' + this.content +
      '\"}';
  }
}
