import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Word } from './word';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Service to be used for getting the {@link Word} objects from the server.
 */
export class RandomwordgenService {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Gets the list of {@link Word} objects from the server.
   */
  public getList(): Observable<Word[]> {
    return this.http.get<Word[]>(window.location.origin + '/words.JSON');
  }
}
