import { Component } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router } from '@angular/router';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {

  constructor(
    private service: AdminService,
    private router: Router
  ) {
  }

  /**
   * Displays a prompt with an input, checks whether this input is the same as the one on the server and if so, redirects the user to
   * the admin space.
   */
  admin() {
    const pass = prompt('Wachtwoord');
    this.service.checkPassword(pass).subscribe(ans => {
      if (ans === true) {
        this.router.navigate(['admin']);
      } else {
      }
    });
  }

  protected readonly faSignInAlt = faSignInAlt;
}
